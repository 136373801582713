// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { AppModules } from '@/Constants/AppModules'
import { AppValues }  from '@/Constants/AppValues'

// Helpers
import { BuilderManager } from '@/Helpers/Components/DataTable/BuilderManager'

// Function Export
export function parseDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, AppModules.Modules.Services.ActionFields.Services)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idClient', '_idEquipment', '_idService', '_idStorage', '_idTechnical', 'clientName', 'clientLastName', 'Description', 'details', 'rasonRejection', 'status', 'storageCode', 'technicalLastName')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('sheet', 'Folio Solicitud').isSortable().setAlign(null, 'center')
		.add('callService', 'Número Llamada').isSortable().setAlign(null, 'center')
		.add('clientEmail', 'Solicitante').isSortable().setAlign(null, 'center').setSearchKey('client.email')
		.add('equipmentCode', 'Cód. Equipo').isSortable().setAlign(null, 'center').setSearchKey('equip.code')
		.add('equipmentName', 'Modelo Equipo').isSortable().setSearchKey('equip.name')
		.add('storageName', 'Planta').isSortable().setSearchKey('storage.name')
		.add('dateCreation', 'Fecha Solicitud').setAlign(null, 'center').setSortDirection('desc')
		.add('dateProgram', 'Fecha Reparación').setAlign(null, 'center').setSortDirection('desc')
		.add('workingDay', 'Jornada').isSortable().setAlign(null, 'center')
		.add('technicalName', 'Técnico Asignado').isSortable().setAlign(null, 'center').setSearchKey('technical.name')
		.add('statusEquipment', 'Estado Equipo').isSortable().setAlign(null, 'center').setSearchKey('dataService.statusEquipment')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('sheet', 'callService', 'clientEmail', 'equipmentCode', 'equipmentName', 'storageName', 'dateCreation', 'dateProgram', 'workingDay', 'technicalName', 'statusEquipment')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('dateProgram')	
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		callService: function(value: number) {
			if (!value) return AppValues.Strings.DEFAULT_EMPTY_STRING
			return value
		},

		dateCreation: (_: void, item: any) => {
			const date = item.dateCreation
			const _date = PrimitiveTools.Dates.parseDateString(date)
			return _date ? `${_date.date} ${_date.time}` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		dateProgram: (_: void, item: any) => {
			if (!PrimitiveTools.Dates.isValid(new Date(item.dateProgram))) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const _date = PrimitiveTools.Dates.parseDateString(item.dateProgram)
			return _date ? `${_date.date} ` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		sheet: (value: number) => {
			if (value === -1) return AppValues.Strings.DEFAULT_EMPTY_STRING
			return `JANSS-${(value).toString().padStart(4, '0')}`
		},

		technicalName: (_: void, item: any) => {
			return (item.technicalName || item.technicalLastName)
				? `${item.technicalName} ${item.technicalLastName}`
				: AppValues.Strings.DEFAULT_EMPTY_STRING;
		},

		workingDay: (v: string) => {
			return v || AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}