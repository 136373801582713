// ./
import { parseDataResponse } from './response.parser'
import dataformElements      from './dataform.elements'

// Classes
import { JoiManager }     from '@/Classes/Network/JoiManager'
import { ActionField }    from '@/Helpers/Components/DataTable/ActionField'
import { DataParsers }    from '@/Classes/Responses/DataParsers'
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'

// Components (.vue)
import BasicHeader   from '@/Components/Global/BasicHeader/template.vue'
import DataTable     from '@/Components/Global/DataTable/template.vue'
import PopupTable    from '@/Components/Global/PopupTable/template.vue'
import PopupUserForm from '@/Components/Global/PopupUserForm/template.vue'

// Components (Refs)
import { DataFormRef }      from '@/Components/Global/DataForm/component'
import { DataTableRef }     from '@/Components/Global/DataTable/component'
import { PopupUserFormRef } from '@/Components/Global/PopupUserForm/component'

// Constants
import { AppModules } from '@/Constants/AppModules'
import { AppValues }  from '@/Constants/AppValues'
import { Component }  from '@/Constants/Component'
import { Server }     from '@/Constants/Server'
import { VueRouter }  from '@/Constants/VueRouter'

// Dependencies
import VueMixins  from 'vue-typed-mixins'

// Mixins
import MixinBase      from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'
import MixinFetch     from '@/Mixins/MixinFetch'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View30 = VueMixins(MixinBase, MixinComponent, MixinFetch).extend({
	name: VueRouter.Views.Modules.SERVICES_CPANEL.NAME,

	components: {
		BasicHeader,
		DataTable,
		PopupTable,
		PopupUserForm
	},

	data: function() {
		return {
			states: {
				dataFormAction: Component.Actions.READ,
				dataTableParser: parseDataResponse,
				documentToUpdate: undefined as any,
				inputButtonKey: undefined as string,
				popupUserFormCancelButtonText: 'Cerrar',
				serviceStatus: undefined as string,
				showDataTable: true,
				showPopupUserForm: false,
				userStorages: [] as Array<any>,
			}
		}
	},

	mounted: function() {
		this._initDataTable()
		this._initPermissions()
		this._initDataForm()
		this._nestedDataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true })
		this._nestedDataTable.setSelectable(true)
		this._popupUserForm.setStates<PopupUserFormRef['states']>({ secondaryButtonAcceptText: 'Aceptar Solicitud', secondaryButtonCancelText: 'Rechazar Solicitud', showSecondaryButtonAccept: true, showSecondaryButtonCancel: true, showUpdate: false })
	},

	computed: {
		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_popupUserForm: function(): PopupUserFormRef {
			return this.$refs.popupUserForm as PopupUserFormRef
		},

		_nestedDataTable: function(): DataTableRef {
			return this._popupUserForm._dataForm._popupTable._dataTable as DataTableRef
		}
	},

	methods: {
		_adjustDateToEndOfDay: function(dateString: string) {
			if (dateString) {
				const date = new Date(dateString)
				date.setUTCHours(18, 59, 59, 999)
				return date.toISOString()
			}
			return null
		},

		_fetchServices: async function(page: number) {
			const user = Store.getters.getStoredUser
			
			// Realizar la petición al Servidor.
			const _idAdminCompany = user._idAdminCompany
			const params   = { _idAdminCompany, itemsPerPage: this._dataTable.itemsPerPage, page }
			const response = await this.fetchURL(Server.Fetching.Method.GET, Server.Routes.ServicesRequest.GetServicesAll, params)

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				return response.data.body[0]
			}
		},

		_initDataForm: function() {
			const { _dataForm } = this._popupUserForm
			_dataForm.initialize(dataformElements)
		},

		_initDataTable: async function(page = 1) {
			const response = await Store.dispatch('fetchServicesForPage', { page })
			const { fields, items, actions } = this.states.dataTableParser(response.data)
			this._dataTable.setStates<DataTableRef['states']>({ exportDataAsAsync: true, isLocalSearch: false })
			this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
		},

		_initPopupUserFormDataTable: function(storages: Array<any>, stackedOrDefault = false) {
			if (storages.length > 0) {
				// Acción para permitir remover la Ubicación de la Tabla.
				const _actions = [
					new ActionField('actions', 'Eliminar').addItem('delete', 'icon').setIcon('times-circle').setPermission('ACTION_DELETE').setVariant('red')
				]
				
				// Convertir los datos para mostrarlos en el <DataTable>.
				const SummaryParser = DataParsers.Storages.GetSummaryParser(this._popupUserForm._dataTable.states.stacked || stackedOrDefault)
				const { fields, items, actions } = SummaryParser(storages, _actions)
				this._popupUserForm._dataTable.setElements(fields, items, actions)
				return
			}
			this._popupUserForm._dataTable.clearData()
		},

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_INTERNAL_USERS?.privileges.write)
			this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_INTERNAL_USERS?.privileges.write)
		},

		_updateDataForm: async function() {
			const { _dataForm } = this._popupUserForm
			const { documentToUpdate } = this.states
			_dataForm.setStates<DataFormRef['states']>({ document: documentToUpdate })

			const dateClean     = documentToUpdate.dateProgram.replace(/\s+/g, '')
			const formattedDate = PrimitiveTools.Dates.reverseDate(dateClean?.replace(AppValues.Strings.DEFAULT_EMPTY_STRING, ''))
			const workingDay    = documentToUpdate.workingDay?.replace(AppValues.Strings.DEFAULT_EMPTY_STRING, '')

			_dataForm.setValue('Description',     documentToUpdate.Description)
			_dataForm.setValue('details',         documentToUpdate.details)
			_dataForm.setValue('technicalName',   documentToUpdate._idTechnical || null)
			_dataForm.setValue('equipmentName',   documentToUpdate.equipmentName)
			_dataForm.setValue('equipmentCode',   documentToUpdate.equipmentCode)
			_dataForm.setValue('statusEquipment', documentToUpdate.statusEquipment)
			_dataForm.setValue('status',          documentToUpdate.status)
			_dataForm.setValue('workingDay',      workingDay || null)
			_dataForm.setValue('dateProgram',     formattedDate)

			// Autocompletar los InputsButtons al momento de editar.
			if (documentToUpdate.technicalName !== AppValues.Strings.DEFAULT_EMPTY_STRING) {
				_dataForm.setValue('technical', documentToUpdate._idTechnical, '_id')
				_dataForm.setValue('technical', documentToUpdate.technicalName, 'value')
			}
			
			_dataForm.setValue('equipment', documentToUpdate._idEquipment, '_id')
			_dataForm.setValue('equipment', documentToUpdate.equipmentName, 'value')
		},

		_updatePopupComponents: async function(preventShowPopupTable: boolean, page = 1, forceRefresh = false) {
			// Propiedades.
			const { _dataForm } = this._popupUserForm
			const key = this.states.inputButtonKey

			// Validaciones Opcionales según Casos.
			if (!preventShowPopupTable) _dataForm.setStates<DataFormRef['states']>({ showPopupTable: true })
			if (forceRefresh) this._nestedDataTable.clearAll()
			this._nestedDataTable.setFetchingState(true)

			if (key === 'technical') {
				// Actualizar el titulo al componente 'PopupTable'.
				_dataForm._popupTable.setTitle('Seleccionar Técnico')

				// Eliminar los Registros si se fuerza una Actualización.
				if (forceRefresh) Store.commit('destroyUsers')
				
				// Aplicar Registros con Paginación.
				const response = await Store.dispatch('fetchUsersForPage', { forceRefresh, isClient: false, page, type: 'Internal' })
				const SummaryParser = DataParsers.Users.GetSummaryInternalsParser(this._nestedDataTable.states.stacked)
				const { fields, items } = SummaryParser(response.data.filter((x: any) => x.roleName === 'Técnico' && x.isValid !== false))
				this._nestedDataTable.updateElementsAndPagination(response.totalPages, fields, items)

				// Aplicar Orden para Columna especifica.
				this._nestedDataTable.resetEmptyText()
				this._nestedDataTable.sortOrder('rut', 'asc')
			}
		},

		onClose: function() {
			// Si 'serviceStatus' es 'undefined', recien ahí cerrar el Formulario.
			if (this.states.serviceStatus === undefined) {
				const { _dataForm } = this._popupUserForm
				_dataForm.clearInputs()
				this.setStates<View30Ref['states']>({ dataFormAction: Component.Actions.READ, documentToUpdate: undefined, showPopupUserForm: false, userStorages: [] })
			}
			
			// Poder cambiar de parecer dentro del mismo formulario.
			else {
				this.setStates<View30Ref['states']>({ dataFormAction: Component.Actions.READ, serviceStatus: undefined })
			}
			
			// Volver a mostrar los botones de 'Aceptar/Rechazar'.
			this.setStates<View30Ref['states']>({ dataFormAction: Component.Actions.READ, popupUserFormCancelButtonText: 'Cerrar', serviceStatus: undefined })
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ showSecondaryButtonAccept: true, showSecondaryButtonCancel: true, showUpdate: false })
		},

		onDataFormSubmit: async function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate } = this.states
			const { _dataForm }        = this._popupUserForm

			// Realizar validación de los Campos.
			if (!_dataForm.validateStates()) {
				this.showToast('Error de Validación', 'Verifica que ningun campo se encuentre marcado de color rojo.', 'danger')
				return
			}

			// Inputs del DataForm. 
			const _idService      = documentToUpdate?._idService
			const _idTechnical    = _dataForm.getValue<string>('technical', '_id')
			const dateProgram     = this._adjustDateToEndOfDay(_dataForm.getValue('dateProgram')) || ''
			const rasonRejection  = _dataForm.getValue<string>('reasonReject')
			const workingDay      = _dataForm.getValue<string>('workingDay')
			const status          = this.states.serviceStatus
			const statusEquipment = _dataForm.getValue('statusEquipment')

			// Objeto con las Propiedades requeridas por la Petición.
			const body = {
				_idService, _idTechnical, dateProgram, rasonRejection, workingDay, statusEquipment, status
			}

			// Validación de los campos de la petición.
			const joiSchema = AppModules.Collections.JoiSchemas.Services.UpdateService
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, 'Error al Actualizar la solicitud de servicio', result.error)

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const response = await this.fetchURL(Server.Fetching.Method.PATCH, Server.Routes.Services.UpdateService, body)

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				this.onDTRefreshButtonClick()
				this.onClose()
				this.showToast('Actualización de Registro', 'El registro a sido actualizado correctamente!', 'success')

				this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
				this.setStates<View30Ref['states']>({ showPopupUserForm: false })
			}
		},

		onDTButtonClick: function(key: string, row: any) {
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ showSecondaryButtonCancel: true })
			if (key === 'edit') {
				this.setStates<View30Ref['states']>({ dataFormAction: Component.Actions.READ, documentToUpdate: row.item, showPopupUserForm: true })
				this._updateDataForm()

				// Ocultar los botones si la 'Solicitud' ya fue procesada.
				const { status } = row.item
				this._popupUserForm.setStates<PopupUserFormRef['states']>({ showSecondaryButtonAccept: status === 'Abierto', showSecondaryButtonCancel: status === 'Abierto' })
			}
		},

		onDTExportDataClick: async function(format: string, filterType: AppValues.DataTableExportDataFilterType, filterValue: AppValues.PeriodsChoiceFriendlyTextList) {
			const user = Store.getters.getStoredUser
			const _idAdminCompany = user._idAdminCompany

			const params = {
				_idAdminCompany,
				itemsPerPage: 1,		// 'itemsPerPage' es Requerido en la Petición pero no es considerada.
				page: 1,				// 'page' es Requerido en la Petición pero no es considerada.
				period: filterValue
			}

			// 'Parser' utilizado para procesar la Respuesta de los Datos.
			const { dataTableParser } = this.states

			// Realizar la Petición al servidor.
			const response = await this.fetchURL(Server.Fetching.Method.GET, Server.Routes.ServicesRequest.GetServicesAll, params)

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response = response.data.body.data
				const { items } = dataTableParser(_response)
				this._dataTable.handleExportDataType(format, items)
			}
		},

		onDTRefreshButtonClick: async function() {
			Store.commit('destroyServices')
			this._initDataTable(1)
		},

		onDTSearchButtonClicked: function(searching: any, page = 1) {
			const { searchKey, searchValue } = searching
			const user = Store.getters.getStoredUser
			const _idAdminCompany = user._idAdminCompany

			// Asignar los parametros por pestaña.
			this._dataTable.doInputSearch(Server.Routes.ServicesRequest.GetServicesRequestBySearchFilter,
				this.states.dataTableParser,
				{
					_idAdminCompany, page, searchKey, searchValue,
					itemsPerPage: this._dataTable.itemsPerPage
				},
				(response) => response.data.body[0]
			)
		},

		onDTPaginationChanged: async function(page: number) {
			// Realizar la petición hacia el servidor.
			const response = await this._fetchServices( page)
			const data = Array.isArray(response) ? response : response.data
			const { fields, items, actions } = this.states.dataTableParser(data)
			this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
			Store.commit('storeServicesForPage', { data, page, totalPages: response.totalPages })
		},
		
		onInputButtonClick: function(key: string) {
			this.setStates<View30Ref['states']>({ inputButtonKey: key })
			this._updatePopupComponents(false)

			// Sobrescribir la 'Página' actual.
			this._nestedDataTable.setPaginationPage(1, true)
		},

		onPTSelect: function(key: string, currentRow: any) {
			const { item } = currentRow
			if (key === 'technical') {
				const { _dataForm } = this._popupUserForm
				_dataForm.setValue(key, item._idUser, '_id')
				_dataForm.setValue(key, item.name, 'value')
			}
		},

		onPUFPaginationChanged: function(page: number) {
			this._updatePopupComponents(false, page)
		},

		onPUFRefreshButtonClick: function() {
			this._updatePopupComponents(false, 1, true)
		},

		onPUFSecondaryButtonClick: function(key: string) {
			switch (key) {
				case 'Accept': {
					this.setStates<View30Ref['states']>({ dataFormAction: Component.Actions.INSERT, showPopupUserForm: true, serviceStatus: AppModules.Modules.Services.Status.ACCEPTED })
					break
				}
				case 'Cancel': {
					this.setStates<View30Ref['states']>({ dataFormAction: Component.Actions.UPDATE, showPopupUserForm: true, serviceStatus: AppModules.Modules.Services.Status.REJECTED })
					break
				}
			}
			this.setStates<View30Ref['states']>({ popupUserFormCancelButtonText: 'Cancelar' })
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ showSecondaryButtonAccept: false, showSecondaryButtonCancel: false, showUpdate: true })
			this._updateDataForm()
		},

		onServerCaughtFetchException: function(path: string, resolution: Server.Fetching.Resolutions, status: number, error: any) {
			// Controlar las excepciones de las diferentes peticiones realizadas en la vista.
			if (path === Server.Routes.Services.UpdateService) {
				this.showToast('Error al Actualizar la Solicitud', error.response.data.body.payload.message, 'danger')
			}
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
		},

		onServerFailedResponse: function(path: string) {
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
		}
	}
})

// Exports
export default View30
export type View30Ref = InstanceType<typeof View30>