// Components
import { DataFormTabDefinition } from '@/Components/Global/DataForm/component'

// Constants
import { Component } from '@/Constants/Component'

// Helpers
import { InputButton }     from '@/Helpers/Components/DataForm/InputButton'
import { InputDatePicker } from '@/Helpers/Components/DataForm/InputDatePicker'
import { InputSelect }     from '@/Helpers/Components/DataForm/InputSelect'
import { InputTextArea }   from '@/Helpers/Components/DataForm/InputTextArea'

// Form Export
export default {
	params: {
		currentTab: 'services',
		numColumns: 2,
	},
	tabs: [
		{
			header: {
				key: 'services',
				text: {
					onInsert: (doc: any) => `Su Solicitud de Servicio${ doc?.sheet ? ` ${ doc?.sheet }` : '' } será aceptada...`,
					onSelect: (doc: any) => `Solicitud de Servicio${ doc?.sheet ? ` ${ doc?.sheet }` : '' }`,
					onUpdate: (doc: any) => `La Solicitud de Servicio${ doc?.sheet ? ` ${ doc?.sheet }` : '' } será rechazada...`
				}
			},
			columns: [
				new InputTextArea('Description', 'Descripción del Servicio')
					.setParamsReadOnly(Component.Actions.UPDATE, Component.Actions.READ, Component.Actions.INSERT)
					.setParamsState((): boolean => null)
				,			
				new InputTextArea('details','Detalle del Servicio')
					.setParamsReadOnly(Component.Actions.UPDATE, Component.Actions.READ, Component.Actions.INSERT)
					.setParamsState((): boolean => null)
				,
				new InputButton('technical', 'Reasignar Técnico')
					.setParamsIcon('pen')
					.setParamsPlaceHolder('Seleccionar Técnico')
					.setParamsReadOnly(Component.Actions.READ, Component.Actions.UPDATE)
				,
				new InputSelect('statusEquipment', 'Estatus Equipo', ['Operativo', 'Detenido'])
					.setParamsReadOnly(Component.Actions.READ, Component.Actions.UPDATE)
				,
				new InputButton('equipment', 'Equipo')
					.setParamsIcon('pen')
					.setParamsReadOnly(Component.Actions.INSERT, Component.Actions.READ, Component.Actions.UPDATE)
					.setParamsState((): boolean => null)
				,
				new InputDatePicker('dateProgram', 'Fecha Reparación')
					.setParamsReadOnly(Component.Actions.READ)
					.setParamsVisible(Component.Actions.INSERT, Component.Actions.READ)
				,
				new InputSelect('workingDay', 'Jornada', ['Mañana', 'Tarde'])
					.setParamsReadOnly(Component.Actions.READ, Component.Actions.UPDATE)
				,
				new InputSelect('reasonReject', 'Motivo Rechazo', ['No disponible el repuesto necesario', 'No es una falla técnica', 'Falta de recursos técnicos', 'No cubre la garantía'])
					.setParamsVisible(Component.Actions.UPDATE)
			]
		}
	] as Array<DataFormTabDefinition>
}